import { SessionState } from './createSession';
import { createEffect, createMemo, createSignal } from 'solid-js';
import { createTokenRefresh } from './createTokenRefresh';
import { decodeToken } from '../utils/jwt-helper';

export type User = {
  _id: string,
  firstName: string,
  lastName: string;
  email: string,
  company: string | null,
  companyName: string | null,
  role: string | null,
  preferredLanguage: string | null,
  permissions: string[] | null,
  tokenExpires: number | null,
  luzmoRealTimeId: string | null,
  luzmoReckoningId: string | null,
  luzmoRealTimeToken: string | null,
  luzmoReckoningToken: string | null,
  moreThanOneReckoning: boolean;
}
const emptyUser: User = {
  _id: '',
  firstName: '',
  lastName: '',
  email: '',
  company: null,
  companyName: null,
  role: null,
  preferredLanguage: null,
  permissions: null,
  tokenExpires: null,
  luzmoRealTimeId: null,
  luzmoReckoningId: null,
  luzmoRealTimeToken: null,
  luzmoReckoningToken: null,
  moreThanOneReckoning: false,
};

const convertTokenData = (data?: Partial<User>): Partial<User> | undefined => data
  ?  ({
    _id: data._id,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    company: data.company,
    companyName: data.companyName,
    role: data.role,
    permissions: data.permissions,
    preferredLanguage: data.preferredLanguage,
    tokenExpires: data.tokenExpires,
  })
  : undefined;

const getUserFromLocalStorage = () => {
  const token = localStorage.getItem('token');
  // if the user changes their name in the settings
  // then the name in the token will be out of date
  // latest name may be stored in localStorage
  const firstName = localStorage.getItem('firstName');
  const lastName = localStorage.getItem('lastName');
  if (!token) return;
  const decodedToken = decodeToken(token);
  const user = convertTokenData(decodedToken) as User;
  return {
    ...user,
    firstName: firstName || user.firstName,
    lastName: lastName || user.lastName,
  };
};

/**
 * Creates a memoized signal of user data that updates when the user signs
 * in or default initializes from local storage.
 */
export const createUser = (session: SessionState, signout: () => void) => {

  const [user, _setUser] = createSignal<User>(emptyUser);
  const [resolved, setResolved] = createSignal<boolean>(false);

  const setUser = (data: User) => {
    localStorage.setItem('firstName', data.firstName);
    localStorage.setItem('lastName', data.lastName);
    _setUser(data);
  };

  // refresh expired tokens
  const userValid = createMemo(() => Date.now() < (user()?.tokenExpires || 0));
  const userExpired = createMemo(() => {
    const { tokenExpires } = user();
    return tokenExpires !== null &&
      tokenExpires !== undefined &&
      Date.now() > tokenExpires;
  });
  const [refresh] = createTokenRefresh(userExpired, signout);

  const isAdmin = createMemo(() => user()?.role === 'Admin');

  createEffect(() => {
    // get newly signed in user
    if (!session().error && !session().loading && session().data) {
      _setUser(convertTokenData(session().data) as User);
      setResolved(true);
    // get refreshed user
    } else if (refresh.state === 'ready' && refresh()?.decoded) {
      _setUser(convertTokenData(refresh().decoded) as User);
      setResolved(true);
    // get user from local storage
    } else {
      const user = getUserFromLocalStorage();
      _setUser(user || emptyUser);
      setResolved(true);
    }
  });

  return {user, setUser, resolved, userValid, isAdmin};
};
