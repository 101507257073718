import Login from './screens/Login';
import LoginForm from './components/LoginForm';
import Main from './screens/Main';
import SaveLocationBeforeLogin from './components/SaveLocationBeforeLogin';
import { Navigate, Route, Router } from '@solidjs/router';
import { Paths } from './config';
import { Show, lazy } from 'solid-js';
import { useState } from './signals/context';
import type { Component } from 'solid-js';

const Assistance = lazy(() => import('./screens/Assistance'));
const ForgotPasswordForm = lazy(() => import('./components/ForgotPasswordForm'));
const Home  = lazy(() => import('./screens/Home'));
const NewPasswordForm = lazy(() => import('./components/NewPasswordForm'));
const Realtime = lazy(() =>  import('./screens/Realtime'));
const Settings  = lazy(() => import ('./screens/Settings'));
const Sonar  = lazy(() => import ('./screens/Sonar'));
const SignupForm = lazy(() => import('./components/SignupForm'));
const SurveyDashboards = lazy(() =>  import('./screens/SurveyDashboards'));
const Surveys = lazy(() => import('./screens/Surveys'));

const Public: Component = () => {
  return (
    <Route component={Login}>
      <Route path={Paths.login} component={LoginForm} />
      <Route path={Paths.passwordReset} component={ForgotPasswordForm} />
      <Route path={Paths.passwordNew + '/:token'} component={NewPasswordForm} />
      <Route path={Paths.register + '/:token'} component={SignupForm} />
      <Route path="*" component={() => <SaveLocationBeforeLogin />} />
    </Route>
  );
};

export const Routes: Component = () => {
  const {user, session} = useState();
  return (
    <Router>
      <Show
        // don't show anything until the user is resolved
        // this avoids running logic too early before the
        // user data is setup.
        when={user.resolved()}
      >
        <Show
          // only show public routes when user is not valid
          // e.g. session expired or never logged in
          when={user.valid()}
          fallback={<Public />}
        >
          <Route component={Main}>
            <Route path={Paths.home} component={Home} />
            <Route path={Paths.realtime} component={Realtime} />
            <Route path={Paths.surveys} component={Surveys} />
            <Route path={Paths.surveys + '/:id'} component={SurveyDashboards} />
            <Route path={Paths.sonar} component={Sonar} />
            <Route path={Paths.settings} component={Settings} />
            <Route path={Paths.assistance} component={Assistance} />
            <Route path={'*'} component={() => <Navigate href={session().redirect() || Paths.home} />} />
          </Route>
        </Show>
      </Show>
    </Router>
  );
};
export default Routes;
