import { gql } from 'graphql-request';
import { request } from '../graphql';

export const GET_SONAR = gql`
query retrieveSonar5AData($filters:LuzmoFiltersInput!){
  retrieveSonar5AData(filters: $filters) {
    label
    category
    importance
    value
  }
}
`;

export const GET_SONAR_AVERAGE = gql`
query retrieveSonar5AAverageData{
  retrieveSonar5AAverageData {
    importance
    value
  }
}
`;

export interface RetrieveSonarData {
  retrieveSonar5AData: Array<SonarFiveAPoint>
}

export interface SonarFiveAPoint {
  label: string;
  category: string;
  importance: number;
  value: number;
  index?: number;
}

export interface RetrieveSonarAverageData {
  retrieveSonar5AAverageData: SonarFiveAAverage
}


export interface SonarFiveAAverage {
  importance: number;
  value: number
}

export type LuzmoFilter = {
  column_id: string;
  dataset_id: string;
  value: string;
 }




export type LuzmoFiltersInput = {
   expression: string;
 parametersInput: LuzmoFilter[];
 }

export const retrieveSonarData = (filters: LuzmoFiltersInput) => request<RetrieveSonarData>(GET_SONAR, {
  filters
});

export const retrieveSonarAverageData = () => request<RetrieveSonarAverageData>(GET_SONAR_AVERAGE);
