import createSignin, { SigninState } from './createSignin';
import { Accessor, ParentComponent, Resource, Setter, createContext, createEffect, createMemo, createSignal, useContext } from 'solid-js';
import { Dashboards, createCompanyDashboards, createMyDashboards } from './createDashboards';
import { GetLuzmoSsoData } from '../requests/evoluno';
import { RecoverState, createRecover } from './createRecover';
import { RegisterState, createRegister } from './createRegister';
import { SessionState, createSession } from './createSession';
import { Signout, createSignout } from './createSignout';
import { User, createUser } from './createUser';
import { createLabels } from './createLabels';
import { createLuzmoSso } from './createLuzmoSso';
import { createLuzmoSsoSurvey } from './createLuzmoSsoSurvey';

export type State = {
  signin: SigninState;
  recover: RecoverState,
  register: RegisterState,
  signout: Signout,
  session: SessionState,
  user: {
    data: Accessor<User>,
    valid: Accessor<boolean>,
    isAdmin: Accessor<boolean>,
    resolved: Accessor<boolean>,
    setUser: (data: User) => void,
  },
  company: {
    override: Accessor<string | undefined>,
    setOverride: Setter<string | undefined>,
  }
  language: Accessor<string>,
  setLanguage: (language: string) => void,
  labels: Resource<unknown>,
  luzmo: {
    sso: {
      rt: Resource<GetLuzmoSsoData['luzmoSSO']>,
      surveys: Resource<GetLuzmoSsoData['luzmoSSO']>,
    }
  },
  realtime: Dashboards['realtime'],
  surveys: Dashboards['surveys'],
}


const StateContext = createContext<State>();

export const StateProvider: ParentComponent = props => {
  const [language, setLanguage] = createSignal(navigator.language.split('-')[0]);
  const [labels] = createLabels(language);
  labels();
  const signout = createSignout(labels);
  const signin = createSignin(signout);
  const recover = createRecover(signout);
  const register = createRegister(signout);
  const session = createSession([signin.data, recover.data, register.data]);
  const { user, setUser, resolved, userValid, isAdmin } = createUser(session, signout);


  const [companyOverride, setCompanyOverride] = createSignal<string | undefined>(undefined);

  const dashboards = createMemo(() => {
    const companyId = companyOverride();
    return companyId
      ? createCompanyDashboards(userValid, companyId)
      : createMyDashboards(userValid);
  });

  const [luzmoRtSso] = createLuzmoSso(userValid, companyOverride);
  const [luzmoSurveySso] = createLuzmoSsoSurvey(dashboards().surveys.selectionId, companyOverride);

  createEffect(() => {
    setLanguage(user()?.preferredLanguage || navigator.language.split('-')[0]);
  });

  const state: State = {
    signin,
    signout,
    recover,
    register,
    session,
    user: {
      data: user,
      isAdmin,
      valid: userValid,
      resolved,
      setUser,
    },
    company: {
      override: companyOverride,
      setOverride: setCompanyOverride,
    },
    language,
    setLanguage,
    labels: labels,
    luzmo: {
      sso: {
        rt: luzmoRtSso,
        surveys: luzmoSurveySso
      }
    },
    realtime: dashboards().realtime,
    surveys: dashboards().surveys,
  };

  return (
    <StateContext.Provider value={state}>
      {props.children}
    </StateContext.Provider>
  );
};

export const useState = () => {
  const state = useContext(StateContext);
  if (!state) {
    throw new Error('useState must be used within a StateProvider');
  }
  return state;
};
