import { Component, Show } from 'solid-js';
import {
  FiFolder,
  FiHome,
  FiLifeBuoy,
  FiSettings,
  FiTrendingUp
} from 'solid-icons/fi';
import { NavigationMenuItem } from './NavigationMenuItem';
import { Paths } from '../../config';
import { useLabels } from '../../signals/useLabels';
import { useState } from '../../signals/context';

const iconClass = `
stroke-content-inverse-primary
group-hover:stroke-content-accent
group-aria-[current=page]:stroke-content-inverse-primary
my-auto aspect-square stroke-2`;

export const NavigationMenu: Component = () => {

  const labels = useLabels('NAVIGATION');
  const {user} = useState();

  const companyThatCanAccessSonar = 'mensura';


  return (
    <div class="relative flex h-full flex-col">
      <div class="mt-40 flex flex-col gap-2">
        <NavigationMenuItem
          to={Paths.home}
          icon={<FiHome class={iconClass} size={24} />}
          text={labels()?.HOME || 'Accueil'}
        />
        <NavigationMenuItem
          to={Paths.realtime}
          icon={<FiTrendingUp class={iconClass} size={24} />}
          text={labels()?.REALTIME || 'Temps réel'}
        />
        <Show when={user.data().companyName?.toLowerCase()?.trim() !== companyThatCanAccessSonar }>
          <NavigationMenuItem
            to={Paths.surveys}
            icon={<FiFolder class={iconClass} size={24} />}
            text={labels()?.SURVEYS || 'Enquêtes'}
          />
        </Show>
        <Show when={user.data().companyName?.toLowerCase()?.trim() === companyThatCanAccessSonar}>
          <NavigationMenuItem
            to={Paths.sonar}
            icon={<FiFolder class={iconClass} size={24} />}
            text={'Sonar'}
          />
        </Show>
      </div>
      <div class="flex grow flex-col justify-end gap-2">
        <hr class="border-surface-inverse-primary"/>
        <NavigationMenuItem
          to={Paths.settings}
          icon={<FiSettings class={iconClass} size={24} />}
          text={labels()?.SETTINGS || 'Paramètres'}
        />
        <NavigationMenuItem
          to={Paths.assistance}
          icon={<FiLifeBuoy class={iconClass} size={24} />}
          text={labels()?.ASSISTANCE || 'Assistance'}
        />
      </div>
    </div>
  );
};

export default NavigationMenu;
