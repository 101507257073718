import Navigation from '../../components/Navigation';
import NavigationTop from '../../components/NavigationTop';
import { ErrorBoundary, ParentComponent, createMemo } from 'solid-js';
import { useLocation } from '@solidjs/router';

export const Main: ParentComponent = props => {
  const location = useLocation();

  const pathname = createMemo(() => location.pathname);

  const baseTailwindclasses = 'bg-surface-primary flex max-h-screen w-full flex-col overflow-y-auto';

  const tailwindClass = createMemo(() => {
    if(pathname() !== '/sonar') {
      return `${baseTailwindclasses} px-16`;
    }
    return baseTailwindclasses;
  });


  return (
    <div>
      <div class="flex min-h-screen">
        <Navigation />
        <div class={tailwindClass()}>
          <NavigationTop/>
          <ErrorBoundary fallback={<p>An error occurred. Please refresh the page.</p>}>
            {props.children}
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
};

export default Main;
