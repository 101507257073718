/* @refresh reload */
import { render } from 'solid-js/web';

import * as Sentry from '@sentry/browser';
import App from './App';
import {
  AMPLITUDE_API_KEY,
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
  SENTRY_REPLAY_ERRORS_RATE,
  SENTRY_REPLAY_SESSION_RATE,
  SENTRY_SAMPLE_RATE,
  getApiUrl
} from './config';
import { ComponentProps } from 'solid-js';
import { ampli } from './ampli';
import './index.css';

export type LuzmoDashboardProps = {
  sonar?:boolean
  dashboardId?: string;
  dashboardSlug?: string;
  itemId?: string;
  itemDimensions?: string;
  authKey?: string;
  authToken?: string;
  language?: string;
  screenMode?: string;
  switchScreenModeOnResize?: boolean;
  loaderBackground?: string;
  loaderFontColor?: string;
  loaderSpinnerColor?: string;
  loaderSpinnerBackground?: string;
  appServer?: string;
  timezoneId?: string;
  apiHost?: string;
  editMode?: string;
  mainColor?: string;
  accentColor?: string;
  callbackAfterReload?: () => void;
}

declare module 'solid-js' {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'luzmo-dashboard': ComponentProps<'div'> & LuzmoDashboardProps;
    }
  }
}

const root = document.getElementById('root');

if (import.meta.env.DEV && !(root instanceof HTMLElement)) {
  throw new Error(
    'Root element not found. Did you forget to add it to your index.html? Or maybe the id attribute got misspelled?',
  );
}

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: SENTRY_SAMPLE_RATE,
  tracePropagationTargets: ['localhost', getApiUrl()],
  replaysOnErrorSampleRate: SENTRY_REPLAY_ERRORS_RATE,
  replaysSessionSampleRate: SENTRY_REPLAY_SESSION_RATE,
});

ampli.load({client: {apiKey: AMPLITUDE_API_KEY }});
addEventListener('beforeunload', async() => {
  ampli.flush();
  await Sentry.flush();
});

render(() => <App/>, root!);
