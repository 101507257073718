export enum Paths {
  actionplans = '/actionplans',
  assistance = '/assistance',
  feedback = '/feedback',
  home = '/home',
  login = '/login',
  passwordNew = '/password/new',
  passwordReset = '/password/reset',
  realtime = '/realtime',
  sonar= '/sonar',
  register = '/register',
  root = '/',
  settings = '/settings',
  surveys = '/surveys',
}